<template>
  <div class="BatchChangeLevelBtn">
    <el-checkbox v-model="checkAll" :indeterminate="isIndeterminate" @change="handleCheckAllChange"></el-checkbox>
    <el-button @click="openDialog" class="batch">批量修改物流方式</el-button>
    <el-dialog :visible.sync="dialogVisible" width="50%">
      <template slot="title">
        <span class="dialog-title">修改物流</span>
      </template>
      <el-form :model="logistics" size="small" :rules="logisticsRules" ref="batchLogisticsForm">
        <el-row>
          <el-col :span="12">
            <el-form-item label="选择物流公司" prop="company">
              <el-select
                v-model="logistics.company"
                placeholder="选择物流公司"
                style="width: 200px"
                @change="selectCompanyChange"
              >
                <el-option v-for="item in options" :key="item.id" :label="item.label" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="选择物流渠道" prop="channel">
              <el-select
                v-model="logistics.channel"
                placeholder="选择物流渠道"
                style="width: 200px"
                @change="selectChannelChange"
              >
                <el-option v-for="item in options" :key="item.id" :label="item.label" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <CommonTable height="400px" :cols="cols" :infoData="tableData" :selection="false">
        <slot>
          <el-table-column label="操作" width="200px" align="center">
            <template slot-scope="{ row, $index }">
              <ColorTextBtn @click="checkOpration($index)">
                {{ row.opration ? '取消选择' : '选择' }}
              </ColorTextBtn>
            </template>
          </el-table-column>
        </slot>
      </CommonTable>
      <PaginationBar
        :total="selectionData.length"
        :page="page"
        :size="size"
        @refreshTableEventFun="handlePageChange"
        style="margin-top: 20px; line-height: 30px"
      ></PaginationBar>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogClick">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { debounce } from '@/utils'
import { deepClone } from '@/components/avue/utils/util'
import { tableCol as cols } from './tableCol'
export default {
  name: 'BatchChangeLevelBtn',
  components: {},
  props: {
    selectionData: {
      type: Array,
      default: () => {
        return []
      }
    },
    allSelectLength: {
      type: Number
    },
    selectionAll: {
      type: Boolean
    },
    isIndeterminateData: {
      type: Boolean
    }
  },
  data() {
    return {
      dialogVisible: false,
      checkAll: false,
      isIndeterminate: false,
      selectionDatas: [],
      page: 1,
      size: 10,
      options: [
        {
          id: '1',
          label: '黄金糕'
        },
        {
          id: '2',
          label: '双皮奶'
        },
        {
          id: '3',
          label: '蚵仔煎'
        }
      ],
      logistics: {
        company: '',
        channel: ''
      },
      cols,
      logisticsRules: {
        company: [{ required: true, message: '请选择物流公司', trigger: 'change' }],
        channel: [{ required: true, message: '请选择物流渠道', trigger: 'change' }]
      }
    }
  },
  computed: {
    tableData() {
      return this.selectionDatas.slice((this.page - 1) * this.size, this.page * this.size)
    }
  },
  watch: {
    allSelectLength(newVal) {
      //   console.log('newVal1', newVal)
      if (!newVal) {
        this.checkAll = false
      } else {
        this.checkAll = true
      }
    },
    selectionAll(newVal) {
      //   console.log('newVal', newVal)
      this.checkAll = newVal
    },
    isIndeterminateData(newVal) {
      this.isIndeterminate = newVal
    },
    selectionData: {
      handler(newVal) {
        const newListData = deepClone(newVal)
        newListData.map((item) => {
          item.opration = true
        })
        this.selectionDatas = newListData
        // console.log('this.selectionDatas', this.selectionDatas)
      },
      deep: true,
      immediate: true
    },
    'logistics.company': {
      handler() {
        this.getChannel()
      }
    }
  },
  methods: {
    handleCheckAllChange(val) {
      this.isIndeterminate = false
      this.$emit('handelCheckAllChange', val)
    },
    selectLevelChange(val) {
      console.log('val', val)
    },
    openDialog: debounce(function () {
      if (!this.selectionData.length) {
        this.$message.warning('请先选择订单')
        return
      }
      this.getCompany()
      this.dialogVisible = true
    }, 300),
    dialogClick() {
      this.$refs['batchLogisticsForm'].validate((valid) => {
        if (valid) {
          this.dialogVisible = false
        } else {
          return false
        }
      })
      //  console.log(this.selectionDatas, 'this.selectionDatas')
    },
    selectCompanyChange() {},
    selectChannelChange() {},
    checkOpration($index) {
      const index = $index + (this.page - 1) * this.size
      this.selectionDatas[index].opration = !this.selectionDatas[index].opration
      // console.log(this.selectionDatas[$index])
    },
    handlePageChange({ page, size }) {
      this.size = size
      this.page = page
    },
    // 获取物流公司
    getCompany() {},
    // 获取物流渠道
    getChannel() {}
  }
}
</script>

<style scoped lang="scss">
.batch.el-button {
  height: 32px;
  background-color: #f5f5f5;
  border: 1px solid #dcdee0;
  border-radius: 2px;
  line-height: 32px;
  padding: 0 10px;
  color: #969799;
}
.el-checkbox {
  margin-right: 20px;
  margin-left: 10px;
}
.dialog-title {
  margin-right: 10px;
  color: #1a1a1a;
}
::v-deep .el-dialog__header {
  line-height: 16px;
  border-bottom: 1px solid #ccc;
}
.input-tips {
  margin: 0;
  font-size: 12px;
  color: #595961;
}
::v-deep .el-form-item__error {
  margin-left: 105px;
}
</style>
